<template>
  <div>
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :value="statusFilter"
      :options="statusOptions"
      class="w-100"
      :reduce="(val) => val.value"
      @input="(val) => $emit('update:statusFilter', val)"
      :placeholder="'Status'" 
    />
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BCardText } from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardActions,
    BCardText,
    vSelect,
  },
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
