<template>
  <div>
    <student-list-add-new
      :is-add-new-student-sidebar-active.sync="isAddNewStudentSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-between mb-1 mb-md-0">
            <div>
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </div>

            <div style="flex-basis: 22%;">
              <!-- Filters -->
              <students-list-filters :status-filter.sync="statusFilter" :status-options="statusOptions" />
            </div>

          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Search..." />
              <b-button
                v-if="$can('student.create')"
                variant="primary"
                @click="isAddNewStudentSidebarActive = true"
                class="ml-1"
              >
                <span class="text-nowrap">Add Student</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refStudentListTable"
        class="position-relative"
        :items="fetchStudents"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Student -->
        <template #cell(student)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(`${data.item.firstName} ${data.item.lastName}`)"
                :variant="`light-${resolveStudentRoleVariant(data.item.status)}`"
                :to="{ name: 'apps-students-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-students-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">#{{ data.item.studentId }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveStudentRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveStudentRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveStudentStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item :to="{ name: 'apps-students-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('student.update')"
              :to="{ name: 'apps-students-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$can('student.archive')" @click="archiveStudent(data.item.id)">
              <feather-icon icon="ArchiveIcon" />
              <span class="align-middle ml-50">Archive</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalStudents"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import StudentsListFilters from './StudentsListFilters.vue';
import useStudentsList from './useStudentsList';
import studentStoreModule from '../studentStoreModule';
import StudentListAddNew from './StudentListAddNew.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    StudentsListFilters,
    StudentListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    archiveStudent(studentId) {
      store
        .dispatch('app-student/updateStudent', {
          id: studentId,
          studentData: { status: 'archived' },
        })
        .then((response) => {
          this.refetchData();
        });
    },

    doExport(ids) {
      this.$http
        .post(`jobs/export`, { entity: 'resume', metadata: { ids } })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Job Started`,
              icon: 'SettingsIcon',
              variant: 'success',
              text: `Your job has been queued for execution. You may leave the site while it is prepared.`,
            },
          });

          this.$router.push({ name: 'apps-job-list' });
        })
        .catch((e) => {
          this.processing = false;
          console.error(e);
        });
    },

    async selectNextPage(page, payload) {
      return new Promise((resolve, reject) => {
        store
          .dispatch('app-student/fetch', { ...payload, page: page })
          .then(resolve)
          .catch(reject);
      });
    },
    async doAllPdfExport() {
      this.processing = true;
      try {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Job Build Started`,
            icon: 'SettingsIcon',
            variant: 'success',
            text: `This may take a moment to generate the job settings, please wait...`,
          },
        });

        const { searchQuery, statusFilter, pathwayFilter, createAtStartFilter, createAtEndFilter } = this;

        const pl = {
          search: searchQuery,
          limit: 100,
        };

        if (statusFilter) {
          pl.status = statusFilter;
        }
        if (pathwayFilter) {
          pl.programName = pathwayFilter;
        }
        // if (createAtStartFilter) {
        //   pl.createAtStart = createAtStartFilter;
        // }
        // if (createAtEndFilter) {
        //   pl.createAtEnd = createAtEndFilter;
        // }

        let currentPage = 1;
        let lastPage = 2;

        let allIds = [];
        let currentFail = 0;

        while (currentPage <= lastPage || currentFail > 5) {
          try {
            const response = await this.selectNextPage(currentPage, pl);

            if (response.status !== 200) {
              currentPage -= 1;
              currentFail += 1;
              continue;
            }

            let { results, totalPages } = response.data;

            console.log(results);
            results.forEach((report) => allIds.push(report.id));

            lastPage = totalPages;
            currentPage += 1;
            currentFail = 0;
          } catch (e) {
            currentFail += 1;
            currentPage -= 1;
          }
        }

        allIds = allIds.filter((value, index, self) => self.indexOf(value) === index);

        this.doExport(allIds);
      } catch (e) {
        console.error(e);
      }

      this.processing = false;
    },
  },
  data: () => {
    return {
      selected: [],
      processing: false,
    };
  },
  mounted() {
    if (this.$route.query.op === 'create') {
      this.isAddNewStudentSidebarActive = true;
    }
  },
  setup() {
    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
    });

    const isAddNewStudentSidebarActive = ref(false);

    const {
      fetchStudents,
      tableColumns,
      perPage,
      currentPage,
      totalStudents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStudentListTable,
      refetchData,

      // UI
      resolveStudentRoleVariant,
      resolveStudentRoleIcon,
      resolveStudentStatusVariant,

      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = useStudentsList();

    return {
      // Sidebar
      isAddNewStudentSidebarActive,

      fetchStudents,
      tableColumns,
      perPage,
      currentPage,
      totalStudents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStudentListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveStudentRoleVariant,
      resolveStudentRoleIcon,
      resolveStudentStatusVariant,

      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    };
  },
  metaInfo() {
    return {
      title: 'Students',
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
